import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  Assessment,
  AssessmentSaveState,
  Product,
  Project,
  Question
} from "../../features/participant-experience/assessments-v3/interfaces/assessment.interface";
import {IGlobal} from "config/config.interface";

export interface UpdateOptionPayload {
  questionId: number;
  optionOrdinal: number;
  answer: any;
  text_value: any;
}

export interface AssessmentStatePayload {
  state: AssessmentSaveState;
  message?: string;
}

const initialState: Assessment = {
  PQPID: 0,
  Questionnaires: [],
  Product: {} as Product,
  Languages: [],
  TotalQuestions: 0,
  CompletedQuestions: 0,
  ObserverLabel: "",
  TargetName: "",
  ObserverType: 0,
  CompletedDate: null,
  OrgID: 0,
  Project: {} as Project,
  IsObserverQuestionnaire: false,
  ShowDemographicsStatusID: 0,
  question_queue: 0,
  save_state: {
    state: AssessmentSaveState.none,
    message: ''
  },
  submitting: false
} as any as Assessment;

const globalConfig = (global as any as IGlobal);

export const assessmentSlice = createSlice({
  name: 'Assessment',
  initialState,
  reducers: {
    setAssessment: (state, action: PayloadAction<Assessment>) => {
      const payload = action.payload;
      state.PQPID = payload.PQPID;
      state.Questionnaires = payload.Questionnaires;
      state.Product = payload.Product;
      state.Languages = payload.Languages;
      state.TotalQuestions = payload.TotalQuestions;
      state.CompletedQuestions = payload.CompletedQuestions;
      state.ObserverLabel = payload.ObserverLabel;
      state.TargetName = payload.TargetName;
      state.ObserverType = payload.ObserverType;
      state.CompletedDate = payload.CompletedDate;
      state.DueDate = payload.DueDate;
      state.OrgID = payload.OrgID;
      state.Project = payload.Project;
      state.IsObserverQuestionnaire = payload.IsObserverQuestionnaire;
      state.ShowDemographicsStatusID = payload.ShowDemographicsStatusID;
      state.sections = payload.sections;
      state.submitting = payload.submitting;
      state.ParticipantId = payload.ParticipantId;
      state.TargetParticipantId = payload.TargetParticipantId;
      state.CultureId = payload.CultureId;
      state.submitting = payload.submitting;
      state.save_state = payload.save_state;
      state.question_queue = payload.question_queue;
    },
    updateAnswer: (state, action: PayloadAction<UpdateOptionPayload>) => {
      const payload = action.payload;
      let questionnaires = state.Questionnaires;
      let answerReq = 0;

      questionnaires = questionnaires.map(q => {
        let question = q.Questions.find(fc => fc.QuestionId === payload.questionId);
        if (!question) return q;

        if (question.QuestionTypeId === globalConfig.config.QUESTION_TYPE_FC) {
          answerReq = 2;
          let option = question?.Options.find(o => o.OptionOrdinal === payload.optionOrdinal);
          let others = question?.Options.filter(o => o.OptionOrdinal !== payload.optionOrdinal);
          if (option) {
            option.Answer = payload.answer;
          }

          if (others) {
            others.forEach(o => {
              if (o.Answer === payload.answer) {
                o.Answer = 0
              }
            });
            if (payload.answer === 5 || payload.answer === 4) {
              others.forEach(o => {
                if (o.Answer === 5 || o.Answer === 4)
                  o.Answer = 0;
              })
            }
            if (payload.answer === 3 || payload.answer === 2) {
              others.forEach(o => {
                if (o.Answer === 3 || o.Answer === 2)
                  o.Answer = 0;
              })
            }
          }
        }

        if (question.QuestionTypeId === globalConfig.config.QUESTION_TYPE_AR || question.QuestionTypeId === globalConfig.config.QUESTION_TYPE_MC) {
          answerReq = 1;
          let option = question?.Options.find(o => o.OptionOrdinal === payload.optionOrdinal);
          if (option) {
            option.Answer = payload.answer;
            option.TextValue = payload.text_value;
          }

          question?.Options.filter(o => o.OptionOrdinal !== payload.optionOrdinal).map(o => {
            o.Answer = 0;
            return o
          });
        }

        if (question.QuestionTypeId === globalConfig.config.QUESTION_TYPE_MS) {
          question?.Options.map(o => {
            o.Answer = payload.answer;
            o.TextValue = payload.text_value;
            return o
          });

          answerReq = 1;
        }


        // This only works for the 'selection' based one - not for OE.
        if (question.Options.filter(o => o.Answer > 0).length >= answerReq) {
          question.Answered = globalConfig.config.QUESTION_FULLYANSWERED;
        } else {
          question.Answered = globalConfig.config.QUESTION_UNANSWERED;
        }


        if (question.QuestionTypeId === globalConfig.config.QUESTION_TYPE_OE) {
          let option = question?.Options.find(o => o.OptionOrdinal === payload.optionOrdinal);
          if (option) {
            option.Answer = 0;
            option.TextValue = payload.answer;
          }
          if (option?.TextValue?.length) {
            question.Answered = globalConfig.config.QUESTION_FULLYANSWERED;
          } else {
            question.Answered = globalConfig.config.QUESTION_UNANSWERED;
          }
        }

        return q;
      })

      state.Questionnaires = questionnaires;
    },
    showInstructions: (state, action: PayloadAction<number>) => {
      const payload = action.payload;
      state.displayed_instructions = payload;
    },
    hideInstructions: (state) => {
      state.displayed_instructions = undefined;
    },
    setFirstUnanswered: (state, action: PayloadAction<Question>) => {
      const payload = action.payload;
      state.first_unanswered_question = payload;
    },
    closeSections: (state) => {
      state.expanded_questionnaires = [];
    },
    expandSection: (state, action: PayloadAction<number[]>) => {
      const payload = action.payload;
      state.highlight_unanswered = false;
      state.expanded_questionnaires = payload;
    },
    highlightUnanswered: (state) => {
      state.highlight_unanswered = true;
    },
    submitAssessment: (state) => {
      state.expanded_questionnaires = [];
      state.submitting = true;
    },
    saveDemographics: (state) => {
      state.ShowDemographicsStatusID = 0;
    },
    markAssessmentAsDone: (state) => {
      state.is_complete = true;
    },
    incrementAnsweredQuestions: (state) => {
      state.question_queue = state.question_queue ? state.question_queue + 1 : 1;
    },
    setAssessmentSaveState: (state, action: PayloadAction<AssessmentStatePayload>) => {
      const payload = action.payload;

      state.save_state = {
        state: payload.state,
        message: payload.message ?? ''
      };
      if (payload.state === AssessmentSaveState.saving)
        state.question_queue = 0;
    }
  }
});

export const {
  setAssessment,
  updateAnswer,
  showInstructions,
  hideInstructions,
  setFirstUnanswered,
  expandSection,
  highlightUnanswered,
  submitAssessment,
  saveDemographics,
  markAssessmentAsDone,
  incrementAnsweredQuestions,
  setAssessmentSaveState
} = assessmentSlice.actions;
export default assessmentSlice.reducer;
