import React from "react";
import { RouteObject } from "react-router";

const Login = React.lazy(() => import('features/login/login.component'));
const LoginLayout = React.lazy(() => import('layout/login.layout'));
const ChangePassword = React.lazy(() => import('features/login/change-password.component'));
const RecoverPassowrd = React.lazy(() => import('features/login/recover-password.component'));
const Logout = React.lazy(() => import('features/login/logout.component'));
const MagicLink = React.lazy(() => import('features/login/magic-link.component'));

export const authRoutes: RouteObject[] = [
    {
        path: '/login/:username?',
        children: [
            { index: true, element: <Login /> }
        ]
    },
    {
        path: '/recover-password/:username?',
        children: [
            { index: true, element: <RecoverPassowrd /> }
        ]
    },
    {
        path: '/change-password/:token?',
        children: [
            { index: true, element: <ChangePassword /> }
        ]
    },
    {
        path: '/logout',
        children: [
            { index: true, element: <Logout /> }
        ]
    },
    {
        path: '/magic-link/:link?',
        children: [
            { index: true, element: <MagicLink /> }
        ]
    }
].map(route => {
    return {
        ...route,
        element: <LoginLayout />
    }
});