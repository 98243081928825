import {Container} from "inversify";
import {RolloutService, IRolloutService} from "lib/services/rollout/rollout.service";
import {TYPES} from "lib/ioc/ioc.types";
import RolloutTargetService, {IRolloutTargetService} from "lib/services/rollout/rollout-target.service";
import {NewApiService} from "lib/services/api/newapi.service";
import {INewApiService} from "lib/services/api/api.interface";
import {IToastService, ToastService} from "lib/services/toast.service";

const iocContainer = new Container();
iocContainer.bind<IRolloutService>(TYPES.RolloutService).to(RolloutService).inSingletonScope();
iocContainer.bind<IRolloutTargetService>(TYPES.RolloutTargetService).to(RolloutTargetService).inSingletonScope();
iocContainer.bind<INewApiService>(TYPES.ApiService).to(NewApiService).inSingletonScope();
iocContainer.bind<IToastService>(TYPES.ToastService).to(ToastService).inSingletonScope();

export {iocContainer};
