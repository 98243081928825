import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import "config/config.js";
import {IAppState, IGlobal} from "config/config.interface";

const globalConfig = (global as any as IGlobal);

const EXPIRED_LOGIN = new Date(new Date().setDate(new Date().getDate() - globalConfig.config.VALID_LOGIN_ACTIVE_DAYS)).toString();
const CURRENT_DATETIME = new Date().toString();

export interface IUserState {
    id: number;
    type: number;
    role: number;
    roleName: string;
    participantId: number;
    isLoggedIn: boolean;
    username: string | null;
    firstName: string | null;
    lastName: string | null;
    fullName: string | null;
    languageId: number;
    languageCode: string;
    participantLanguageId: number;
    participantLanguageCode: string;
    lastActive: string;
    user_token: string;
    email: string;
    redirect?: IRedirectData;
    unknownParticipant?: boolean;
}

export interface IRedirectData {
  url: string;
  pqp: number;
}

export interface IUpdateParticipant {
    firstName?: string;
    lastName?: string;
    email?: string;
    username?: string;
}

const initialState: IUserState = {
    id: 0,
    type: 0,
    role: 0,
    roleName: "Participant",
    participantId: 0,
    isLoggedIn: false,
    username: null,
    firstName: null,
    lastName: null,
    fullName: null,
    languageId: 1,
    languageCode: "en-US",
    participantLanguageId: 1,
    participantLanguageCode: "en-US",
    lastActive: EXPIRED_LOGIN,
    user_token: '',
    email: '',
    redirect: undefined
};


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, action) => {
            const payload = action.payload;
            state.id = payload.id;
            state.type = payload.type;
            state.role = payload.role;
            state.roleName = payload.roleName;
            state.participantId = payload.participantId;
            state.isLoggedIn = true;
            state.username = payload.username;
            state.firstName = payload.firstName;
            state.lastName = payload.lastName;
            state.fullName = payload.fullName;
            state.languageId = payload.languageId;
            state.languageCode = globalConfig.config.LANGUAGES[payload.languageId].code;
            state.participantLanguageId = payload.participantLanguageId;
            state.participantLanguageCode = globalConfig.config.LANGUAGES[payload.participantLanguageId].code;
            state.lastActive = CURRENT_DATETIME;
            state.user_token = payload.user_token;
            state.email = payload.email;
            state.redirect = {
              pqp: payload.redirect.assessmentRedirect,
              url: payload.redirect.submitRedirect
            };
              state.unknownParticipant = payload.unknownParticipant;
              const reRoute = payload.unknownParticipant ? "/profile" : "/";
            setTimeout(() => {
                window.location.href = reRoute
            }, 150);
        },
        changeUser: (state, action: PayloadAction<IUpdateParticipant>) => {
            const payload = action.payload;
            state.firstName = payload.firstName ?? state.firstName;
            state.lastName = payload.lastName ?? state.lastName;
            state.username = payload.username ?? state.username;
            state.email = payload.email ?? state.email;
            state.fullName = `${state.firstName} ${state.lastName}`;
        },
        changeAssessmentLanguage: (state, action) => {
            const payload = action.payload;
            state.participantLanguageId = payload.id;
            state.participantLanguageCode = globalConfig.config.LANGUAGES[payload.id].code;
            setTimeout(() => {
              window.location.reload();
            }, 300);
        },
        logOut: (state) => {
            state = {} as any;
            setTimeout(() => {
                localStorage.removeItem('persist:quest')
                window.location.href = '/login';
            }, 250);
        },
        updateToken: (state, action) => {
            const payload = action.payload;
            state.user_token = payload;
        },
        clearRedirectPqp: (state) => {
          if(state.redirect)
            state.redirect.pqp = 0;
        }
    }
});

export const isLoggedIn = (state: IUserState) => {
    return state.isLoggedIn;
}

const selectAuth = (state: IAppState) => state.user;
export const selectAuthToken = createSelector(
  selectAuth,
  (state) => state.user_token
);


export const { login, changeUser, changeAssessmentLanguage, logOut, updateToken, clearRedirectPqp } = userSlice.actions;
export default userSlice.reducer;

