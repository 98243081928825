import {configureStore, ThunkAction, Action, combineReducers} from '@reduxjs/toolkit';
import {persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import userSlice from 'lib/reducers/user.slice';
import {airtableApi} from "lib/reducers/airtable.slice";
import {questApi} from "lib/reducers/api.slice";
import {assessmentSlice} from "lib/reducers/assessment.slice";


const userConfig = {
  key: 'quest',
  storage,
}

// @ts-ignore
const allReducers = combineReducers({
  user: persistReducer(userConfig, userSlice),
  assessment: assessmentSlice.reducer,
  [airtableApi.reducerPath]: airtableApi.reducer,
  [questApi.reducerPath]: questApi.reducer,
})

// @ts-ignore
export const store = configureStore({
  reducer: allReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      }
    }).concat(airtableApi.middleware).concat(questApi.middleware)
});

export const persistor = persistStore(store)
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
