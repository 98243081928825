import {FlagContainer} from "lib/services/rollout/rollout.interface";
import * as Rox from 'rox-browser';

export interface RolloutFlags extends FlagContainer {
  user: {
    navigation_dashboard: Rox.Flag;
    navigation_projects: Rox.Flag;
    navigation_reports: Rox.Flag;
    navigation_clients: Rox.Flag;
    navigation_participants: Rox.Flag;
    globalSearch: Rox.Flag;
  }
}

export const InitializeRolloutFlags: () => RolloutFlags = () =>
  ({
    user: {
      navigation_dashboard: new Rox.Flag(false),
      navigation_projects: new Rox.Flag(false),
      navigation_reports: new Rox.Flag(false),
      navigation_clients: new Rox.Flag(false),
      navigation_participants: new Rox.Flag(false),
      globalSearch: new Rox.Flag(false),
    }
  });
