import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {IGlobal} from "config/config.interface";
import {
  IAirtableRequest,
  IKbArticleItem,
  IPriceListItem,
  ISettingsItem,
  ISidebarItem
} from "lib/interfaces/airtable.interface";

const globalConfig = (global as any) as IGlobal;
export const airtableApi = createApi({
  reducerPath: 'airtableApi',
  baseQuery: fetchBaseQuery({
    baseUrl: globalConfig.config.AIRTABLEAPI_URL,
    headers: {Authorization: `Bearer ${globalConfig.config.AIRTABLE_KEY}`}
  }),
  endpoints: (builder) => ({
    getSidebarItems: builder.query<IAirtableRequest<ISidebarItem>, any>({
      query: (view) => `sidebar-nav${view ? '?view=' + view : ''}`
    }),
    getKbItems: builder.query<IAirtableRequest<IKbArticleItem>, any>({
      query: () => `sidebar-kb`
    }),
    getSettingsConfig: builder.query<IAirtableRequest<ISettingsItem>, any>({
      query: (view) => `settings${view ? '?view=' + view : ''}`
    }),
    getPriceList: builder.query<IAirtableRequest<IPriceListItem>, any>({
      query: (view) => `price-list${view ? '?view=' + view: ''}`
    })
  })
})

export const {useGetSidebarItemsQuery, useGetKbItemsQuery, useGetSettingsConfigQuery, useGetPriceListQuery} = airtableApi;

