import {RolloutTarget} from "./targets.enum";
import {RolloutFlags} from "./flags";
import * as Rox from "rox-browser";
import {RolloutService} from "lib/services/rollout/rollout.service";
import {inject, injectable} from "inversify";
import {TYPES} from "lib/ioc/ioc.types";

@injectable()
export default class RolloutTargetService implements IRolloutTargetService {
  @inject(TYPES.RolloutService) rollout!: RolloutService;

  selectorMap: Record<RolloutTarget, (f: RolloutFlags) => Rox.Flag> = {
    [RolloutTarget.CertifiedUser_Navigate_Dashboard]: (f) => f.user.navigation_dashboard,
    [RolloutTarget.CertifiedUser_Component_GlobalSearch]: (f) => f.user.globalSearch,
    [RolloutTarget.CertifiedUser_Navigate_Projects]: (f) => f.user.navigation_projects,
    [RolloutTarget.CertifiedUser_Navigate_Clients]: (f) => f.user.navigation_clients,
    [RolloutTarget.CertifiedUser_Navigate_Reports]: (f) => f.user.navigation_reports,
    [RolloutTarget.CertifiedUser_Navigate_Participants]: (f) => f.user.navigation_participants,
  };

  IsFlagActive(rolloutTarget: RolloutTarget): boolean {
    return this.selectorMap[rolloutTarget] && this.rollout.isFlagActive(this.selectorMap[rolloutTarget]);
  }
}

export interface IRolloutTargetService {

  selectorMap: Record<RolloutTarget, (f: RolloutFlags) => Rox.Flag>;

  IsFlagActive(rolloutTarget: RolloutTarget): boolean;
}
