import React from "react";
import {RouteObject} from "react-router";
import {RequireAuth} from "features/auth/auth.guard";
import {RolloutTargetGuard} from "../features/rollout/rollout.guard";
import {RolloutTarget} from "lib/services/rollout/targets.enum";

const UserLayout = React.lazy(() => import('layout/user.layout'));
const UserDashboard = React.lazy(() => import('features/user-experience/dashboard/dashboard.component'));

export const userRoutes: RouteObject[] = [
    {
        path: '/manage',
        children: [
            { index: true, element:
                <RequireAuth permission='MRG Customer Service'>
                  <RolloutTargetGuard flag={RolloutTarget.CertifiedUser_Navigate_Dashboard} reroute={`${process.env.REACT_APP_URL_CERTIFIED_USER}/Pages/Dashboard.aspx`}>
                    <UserDashboard />
                  </RolloutTargetGuard>
                </RequireAuth> },
            { path: 'projects', element:
                <RequireAuth permission='MRG Customer Service'>
                  <RolloutTargetGuard flag={RolloutTarget.CertifiedUser_Navigate_Projects} reroute={`${process.env.REACT_APP_URL_CERTIFIED_USER}/Pages/ProjectsIndex.aspx`}>
                    <div>Projects</div>
                  </RolloutTargetGuard>
                </RequireAuth> },
            { path: 'reports', element:
                <RequireAuth permission='MRG Customer Service'>
                  <RolloutTargetGuard flag={RolloutTarget.CertifiedUser_Navigate_Reports} reroute={`${process.env.REACT_APP_URL_CERTIFIED_USER}/Pages/ReportOrdering.aspx`}>
                    <div>Reports</div>
                  </RolloutTargetGuard>
                </RequireAuth> },
            { path: 'clients', element:
                <RequireAuth permission='MRG Customer Service'>
                  <RolloutTargetGuard flag={RolloutTarget.CertifiedUser_Navigate_Clients} reroute={`${process.env.REACT_APP_URL_CERTIFIED_USER}/Pages/CompanyIndex.aspx`}>
                    <div>Clients</div>
                  </RolloutTargetGuard>
                </RequireAuth> },
            { path: 'participants', element:
                <RequireAuth permission='MRG Customer Service'>
                  <RolloutTargetGuard flag={RolloutTarget.CertifiedUser_Navigate_Participants} reroute={`${process.env.REACT_APP_URL_CERTIFIED_USER}/Pages/ParticipantIndex.aspx`}>
                    <div>Participants</div>
                  </RolloutTargetGuard>
                </RequireAuth> },

        ]
    }
].map(route => {
    return {
        ...route,
        element: <UserLayout />
    }
});
