import React from "react";
import App from "App";

import { authRoutes } from "./auth.routes";
import { participantRoutes } from './participant.routes';
import { userRoutes } from "./user.routes";

import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";
import { RequireAuth } from "features/auth/auth.guard";

const RootLayout = React.lazy(() => import('layout/root.layout'));

export default function BuildRoutingTree() {
    return createBrowserRouter(routes)
}

const routes: RouteObject[] = [
    {
        path: '/',
        element: <RootLayout />,
        children: [
            { index: true, element: <RequireAuth><App /></RequireAuth> },
            ...participantRoutes,
            ...authRoutes,
            ...userRoutes
        ]
    },
    {
        path: '*',
        element: <Navigate to="/assessments" replace />
    }
];
